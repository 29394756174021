<template>
  <v-card flat class="fill-height" color="transparent" ref="parent-height">
    <scroll-div :height="fixedHeight + 'px'" view-class="scroll-view">
      <data-table-shared :headers="commentHeaders" :items="commentItems" height="" :perPage="100" :hideFooter="true" />
      <data-table-shared :headers="deviceInfoHeaders" :items="deviceInfoItems" height="" :perPage="100" :hideFooter="true" />
      <data-table-shared :headers="providerInfoHeaders" :items="providerInfoItems" height="" :perPage="100" :hideFooter="true" />
      <data-table-shared :headers="serviceInfoHeaders" :items="serviceInfoItems" height="" :perPage="100" :hideFooter="true" />
      <data-table-shared :headers="subscriberInfoHeaders" :items="subscriberInfoItems" height="" :perPage="100" :hideFooter="true" />
    </scroll-div>
  </v-card>
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
import RearrangeNestedObject from '@/mixins/rearrange-nested-object.mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'AdrInformationComponent',
  mixins: [parentHeight, RearrangeNestedObject],
  components: {
    DataTableShared
  },
  data: () => ({
    commentHeaders: [
      { text: 'Comment Info', value: 'name', width: '40%' },
      { text: 'Details', value: 'value', width: '60%' }
    ],
    deviceInfoHeaders: [
      { text: 'Device Info', value: 'name', width: '40%' },
      { text: 'Details', value: 'value', width: '60%' }
    ],
    providerInfoHeaders: [
      { text: 'Provider Info', value: 'name', width: '40%' },
      { text: 'Details', value: 'value', width: '60%' }
    ],
    serviceInfoHeaders: [
      { text: 'Service Info', value: 'name', width: '40%' },
      { text: 'Details', value: 'value', width: '60%' }
    ],
    subscriberInfoHeaders: [
      { text: 'Subscriber Info', value: 'name', width: '40%' },
      { text: 'Details', value: 'value', width: '60%' }
    ],
    commentItems: [],
    deviceInfoItems: [],
    providerInfoItems: [],
    serviceInfoItems: [],
    subscriberInfoItems: []
  }),
  computed: {
    ...mapGetters('adr', [
      'commentGetter',
      'deviceInfoGetter',
      'providerInfoGetter',
      'serviceInfoGetter',
      'subscriberInfoGetter',
      'adrUpdateGetter'
    ])
  },
  watch: {
    'adrUpdateGetter' () {
      this.commentItems = []
      this.deviceInfoItems = []
      this.providerInfoItems = []
      this.serviceInfoItems = []
      this.subscriberInfoItems = []

      this.getNestedObject(this.commentGetter, 'commentItems', false)
      this.getNestedObject(this.deviceInfoGetter, 'deviceInfoItems', false)
      this.getNestedObject(this.providerInfoGetter, 'providerInfoItems', false)
      this.getNestedObject(this.serviceInfoGetter, 'serviceInfoItems', false)
      this.getNestedObject(this.subscriberInfoGetter, 'subscriberInfoItems', false)
    }
  }
}
</script>

<style>
.scroll {
  overflow-y: scroll
}
</style>
