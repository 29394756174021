<template>
  <vue-draggable-resizable
    :draggable="config.draggable"
    class="chat-one"
    v-if="modal"
    :class="{
      maximized: curSize === chatSizes.EXPAND,
      minimized: curState === chatStates.MINIMIZED
    }"
    :style="{
      right: right + count * 400 + 'px',
      position: config.position
    }"
  >
    <div class="chat-one-header">
      <div class="chat-one-title" v-if="config.title">{{ title }}</div>
      <div
        v-if="config.buttons.minimizeButton"
        class="chat-one-button minimize-chat v-icon mdi"
        :class="{
          'mdi-window-minimize': curState === chatStates.MAXIMIZED,
          'mdi-window-maximize': curState === chatStates.MINIMIZED,
        }"
        :title="curState === chatStates.MINIMIZED ? 'Maximize' : 'Minimize'"
        @click="minimize"
      ></div>
      <div
        v-if="config.buttons.maximizeButton"
        class="chat-one-button expand-chat v-icon mdi"
        :class="{
          'mdi-fullscreen': curSize === chatSizes.NORMAL,
          'mdi-arrow-collapse-all': curSize === chatSizes.EXPAND
        }"
        :title="curSize === chatSizes.NORMAL ? 'Expand' : 'Collapse'"
        @click="maximize"
      ></div>
      <div v-if="config.buttons.closeButton" class="chat-one-button close-chat v-icon mdi mdi-close" title="Close" @click="close"></div>
    </div>
    <div class="chat-one-body">
      <div v-for="(message, index) in messages" :key="index" :class="{me: message.sender === messageTypes.own}" class="chat-one-message">
        {{message.message}}
      </div>
    </div>
    <div class="chat-one-footer">
      <textarea v-model="message"></textarea>
      <div class="v-icon mdi mdi-send send"></div>
    </div>
  </vue-draggable-resizable>
</template>
<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import { chatSizes, chatStates, messageTypes } from '@/app/widgets/chat-widget/constants/constants.js'

export default {
  name: 'ChatComponent',
  props: ['count', 'data', 'no-minimize-button', 'no-maximize-button', 'no-title', 'no-close-button', 'draggable', 'modal'],
  components: {
    VueDraggableResizable
  },
  data () {
    return {
      right: 10,
      title: 'Here will go title',
      curSize: chatSizes.NORMAL,
      message: '',
      sendRtt: false,
      messages: [],
      curState: chatStates.MAXIMIZED,

      chatSizes,
      chatStates,
      messageTypes,

      pager: {
        curPage: 1,
        loading: false,
        totalPages: 2
      }
    }
  },
  computed: {
    config () {
      const config = {
        buttons: {},
        position: 'relative'
      }

      if (this.$props.modal !== undefined) {
        config.draggable = true
        config.position = 'fixed'

        config.title = true
        config.buttons.closeButton = true
        config.buttons.minimizeButton = true
        config.buttons.maximizeButton = true
      }

      config.title = !(this.$props.noTitle !== undefined)
      config.draggable = !(this.$props.draggable !== undefined)
      config.buttons.closeButton = !(this.$props.noCloseButton !== undefined)
      config.buttons.minimizeButton = !(this.$props.noMinimizeButton !== undefined)
      config.buttons.maximizeButton = !(this.$props.noMaximizeButton !== undefined)

      return config
    }
  },
  methods: {
    minimize () {
      this.curState = (this.curState === this.chatStates.MINIMIZED ? this.chatStates.MAXIMIZED : this.chatStates.MINIMIZED)
    },
    maximize () {
      this.curSize = (this.curSize === this.chatSizes.NORMAL ? this.chatSizes.EXPAND : this.chatSizes.NORMAL)
    },
    close () {
      this.$emit('remove', this.count)

      // destroy the vue listeners, etc
      this.$destroy()
      // remove the element from the DOM
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/components/chat';
</style>
