<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="warning"

        @click="resolveCall(data)"
        small
        min-width="0"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
      >
        <v-icon small color="white">
          mdi-check
        </v-icon>
      </v-btn>
    </template>
    <span>Resolve</span>
  </v-tooltip>
</template>

<script>
/* Vuex */
import { mapActions } from 'vuex'

export default {
  name: 'CallResolveButton',
  props: ['data'],
  methods: {
    ...mapActions('abandonedCalls', ['updateAbandonCallStatusAction']),
    resolveCall (data) {
      this.$confirm('Please confirm that you want to resolve this call').then(confirmed => {
        if (confirmed) {
          this.updateAbandonCallStatusAction({ callId: data.call_id, status: 'resolved' })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
