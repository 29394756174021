<template>
  <v-btn
      id="action_KeyR"
      cypress="registration"
      class="text-icon-button"
      @click.once="changeState"
      :disabled="!sipGetter.connected"
      :loading="sipGetter.registering"
      :color="sipGetter.registered ? 'success' : 'error'"
      hide-details
      width="100%"
      height="100%"
      :key="buttonKey"
  >
    <v-icon>{{ sipGetter.registered ? 'mdi-checkbox-marked-circle' : 'mdi-cancel' }}</v-icon>
    <div class="font-weight-bold pt-2">{{ sipGetter.registered ? $t('ready') : $t('not_ready') }}</div>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'ReadyStateButton',
  mixins: [clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  },
  methods: {
    ...mapMutations('global', [
      'sipMutator'
    ]),
    changeState () {
      if (this.sipGetter.registered) {
        this.sipMutator({ type: 'registering', data: true })
        this.$sip.unregisterUA()
      } else {
        this.sipMutator({ type: 'registering', data: true })
        this.$sip.registerUA()
      }
      this.refreshButton(1500)
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>
