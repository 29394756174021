<template>
  <DialogShared v-model="dialog" :eager="true" max-width="1200" title="Call Detail Information" @input="closed">
    <tabs-shared :items="items"/>
  </DialogShared>
</template>

<script>
/* Shared */
import tabsShared from '@/app/shared/tabs/TabsShared'
/* Components */
import DialogShared from '@/app/shared/dialog/DialogShared'
import CallDetailsInformationComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsInformationComponent'
import CallDetailsHeldComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsHeldComponent'
import CallDetailsAdrComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsAdrComponent'
import CallDetailsMapComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsMapComponent'
import CallDetailsNotesComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsNotesComponent'
import CallDetailsTextComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsTextComponent'
import CallDetailsExtraDetailsComponent from '@/app/widgets/call-detail-tabs-widget/components/tabs/CallDetailsExtraDetailsComponent'

import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'CallDetailTabsComponent',
  components: {
    tabsShared,
    DialogShared
  },
  data: (vm) => ({
    callDetailModal: false,
    dialog: false,
    items: [
      {
        tab: vm.$t('call_information'),
        name: 'call-information',
        component: CallDetailsInformationComponent,
        eager: true
      },
      {
        tab: vm.$t('held'),
        name: 'held',
        component: CallDetailsHeldComponent,
        eager: true
      },
      {
        tab: vm.$t('adr'),
        name: 'adr',
        component: CallDetailsAdrComponent,
        eager: true
      },
      {
        tab: vm.$t('notes'),
        name: 'notes',
        component: CallDetailsNotesComponent,
        eager: true
      },
      {
        tab: vm.$t('details'),
        name: 'details',
        component: CallDetailsExtraDetailsComponent,
        eager: true
      },
      {
        tab: vm.$t('text'),
        name: 'text',
        component: CallDetailsTextComponent,
        eager: true
      },
      {
        tab: vm.$t('map'),
        name: 'map',
        component: CallDetailsMapComponent,
        eager: true
      }
    ]
  }),
  watch: {
    'callDetailsModalGetter' (val) {
      if (val) this.dialog = true
    }
  },
  computed: {

    ...mapGetters('callDetails', ['callDetailsModalGetter'])
  },
  methods: {
    ...mapMutations('callDetails', [
      'clearCallDetailsMutator',
      'clearCallDetailsHeldMutator',
      'clearCallDetailsAdrMutator',
      'callDetailsModalMutator'
    ]),
    closed (val) {
      if (!val) {
        this.clearCallDetailsMutator()
        this.clearCallDetailsHeldMutator()
        this.clearCallDetailsAdrMutator()
        this.callDetailsModalMutator()
      }
    }
  }
}
</script>

<style scoped>

</style>
