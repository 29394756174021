import heldParser from '@/modules/api/csp/parsers/held'
import store from '@/store'

export default {
  methods: {
    async getHeld (session, populate = false) {
      return await heldParser(session).parse().then(async data => {
        if (populate) {
          await this.clearHeld()
          await store.commit('held/heldMutator', data)
          await store.commit('held/heldUpdateMutator')
        } else return data
      })
    },
    async clearHeld () {
      await store.commit('held/clearHeldMutator')
      await store.commit('held/heldUpdateMutator')
    }
  }
}
