import store from '@/store'
export default {
  methods: {
    setLocations (val) {
      if (val) {
        this.markers.push(
          {
            position: {
              lat: parseFloat(this.heldGetter.lat),
              lng: parseFloat(this.heldGetter.lng)
            },
            address: `${store.getters['held/heldGetter'].civicAddress.RD || ''} ${store.getters['held/heldGetter'].civicAddress.HNO || ''} ${store.getters['held/heldGetter'].civicAddress.country}`
          }
        )
        this.map = {
          lat: parseFloat(this.heldGetter.lat),
          lng: parseFloat(this.heldGetter.lng),
          zoom: 18
        }
      } else {
        this.clearLocation()
      }
    },
    clearLocation () {
      if (!store.getters['global/callGetter'].hasActiveCall) {
        this.markers = []
        this.map = {
          lat: 39.8097343,
          lng: -98.5556199,
          zoom: 4.5
        }
      }
    }
  }
}
