<template>
  <v-bottom-sheet
    eager
    v-model="toggle"
    inset
    hide-overlay
    persistent
    no-click-animation
  >
    <v-card tile>
      <v-slider
        thumb-color="transparent"
        height="0"
        color="error"
        :max="duration"
        min="0"
        step="0.01"
        :value="currentTime"
        @change="moveProgressBar($event)"
      ></v-slider>

      <v-list class="pa-0">
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-list-item-title>{{ `${displayCurrentTime} - ${getTotalDuration}` }}</v-list-item-title>
          </v-list-item-content>

          <v-spacer></v-spacer>

          <v-list-item-icon class="mt-0">
            <v-btn icon>
              <v-icon @click="togglePlayer"> {{ this.paused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
    <audio id="live-recording" ref="live-recording"></audio>
  </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import LiveRecordingMixin from '@/mixins/live-recording.mixin'

export default {
  name: 'LiveRecordingComponent',
  mixins: [LiveRecordingMixin],
  data: () => ({
    toggle: false,
    mediaRecorder: null,
    timeSlice: 1000,
    chunks: [],
    audioUrl: null,
    paused: true,
    duration: 0,
    currentTime: 0
  }),
  computed: {
    ...mapGetters('recorder', [
      'recordGetter',
      'toggleGetter'
    ]),
    displayCurrentTime () {
      return this.convertSecondsToHms(this.currentTime)
    },
    getTotalDuration () {
      return this.convertSecondsToHms(this.duration)
    }
  },
  watch: {
    toggleGetter (val) {
      this.toggle = val
    },
    recordGetter (val) {
      val ? this.startRecording() : this.stopRecording()
    },
    chunks (val) {
      const audioBlob = new Blob(val, { type: 'audio/ogg; codecs=opus' })
      this.duration++
      this.audioUrl = window.URL.createObjectURL(audioBlob)
      this.replaceAudioSource()
    }
  },
  methods: {
    ...mapMutations('recorder', [
      'recordMutator',
      'toggleMutator'
    ]),
    convertSecondsToHms (seconds) {
      if (seconds > 3600) {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      } else {
        return new Date(seconds * 1000).toISOString().substr(11, 8)
      }
    },
    togglePlayer () {
      if (this.$refs['live-recording'].paused) {
        this.$refs['live-recording'].src = this.audioUrl
        this.$refs['live-recording'].currentTime = this.currentTime
        this.$refs['live-recording'].play()
        this.paused = false
      } else {
        this.currentTime = this.$refs['live-recording'].currentTime
        this.$refs['live-recording'].pause()
        this.paused = true
      }
    },
    replaceAudioSource () {
      if (this.$refs['live-recording'].paused) {
        this.$refs['live-recording'].src = this.audioUrl
      } else {
        this.$refs['live-recording'].pause()
        this.currentTime = this.$refs['live-recording'].currentTime
        this.$refs['live-recording'].src = this.audioUrl
        this.$refs['live-recording'].currentTime = this.currentTime
        this.$refs['live-recording'].play()
      }
    },
    moveProgressBar (val) {
      this.currentTime = val
      this.$refs['live-recording'].currentTime = this.currentTime
    }
  }
}
</script>

<style>
.v-slider--horizontal {
  margin-left: 0;
  margin-right: 0;
}
</style>
