var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modal)?_c('vue-draggable-resizable',{staticClass:"chat-one",class:{
    maximized: _vm.curSize === _vm.chatSizes.EXPAND,
    minimized: _vm.curState === _vm.chatStates.MINIMIZED
  },style:({
    right: _vm.right + _vm.count * 400 + 'px',
    position: _vm.config.position
  }),attrs:{"draggable":_vm.config.draggable}},[_c('div',{staticClass:"chat-one-header"},[(_vm.config.title)?_c('div',{staticClass:"chat-one-title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.config.buttons.minimizeButton)?_c('div',{staticClass:"chat-one-button minimize-chat v-icon mdi",class:{
        'mdi-window-minimize': _vm.curState === _vm.chatStates.MAXIMIZED,
        'mdi-window-maximize': _vm.curState === _vm.chatStates.MINIMIZED,
      },attrs:{"title":_vm.curState === _vm.chatStates.MINIMIZED ? 'Maximize' : 'Minimize'},on:{"click":_vm.minimize}}):_vm._e(),(_vm.config.buttons.maximizeButton)?_c('div',{staticClass:"chat-one-button expand-chat v-icon mdi",class:{
        'mdi-fullscreen': _vm.curSize === _vm.chatSizes.NORMAL,
        'mdi-arrow-collapse-all': _vm.curSize === _vm.chatSizes.EXPAND
      },attrs:{"title":_vm.curSize === _vm.chatSizes.NORMAL ? 'Expand' : 'Collapse'},on:{"click":_vm.maximize}}):_vm._e(),(_vm.config.buttons.closeButton)?_c('div',{staticClass:"chat-one-button close-chat v-icon mdi mdi-close",attrs:{"title":"Close"},on:{"click":_vm.close}}):_vm._e()]),_c('div',{staticClass:"chat-one-body"},_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"chat-one-message",class:{me: message.sender === _vm.messageTypes.own}},[_vm._v(" "+_vm._s(message.message)+" ")])}),0),_c('div',{staticClass:"chat-one-footer"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('div',{staticClass:"v-icon mdi mdi-send send"})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }