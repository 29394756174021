<template>

    <v-data-table
      class="px-4 py-2 transparent-background"
      :headers="headers"
      :items="getContactBookGetter.data"
      :search="search"
      :options.sync="options"
      item-key="id"
      selectable-key="id"
      single-select
      :loading-text="$t('loading')"
      :server-items-length.sync="getContactBookGetter.meta.total"
      dense
      :items-per-page="10"
      :dark="$vuetify.theme.dark"
      :height="300"

    >
      <template v-slot:[`top`]>
        <div class="d-flex" >

          <v-text-field :disabled="loading" @keyup.enter="getContactBook" v-model="search" clearable @click:clear="clearSearch"
                        class="mr-8"
                        prepend-icon="mdi-magnify"
                        :label="$t('search')"
          >

            <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading" @click="getContactBook()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

          </v-text-field>
        </div>
      </template>

      <template v-slot:[`item.contact_permission`]="{ item }">
        <v-btn icon small v-if="item.contact_permission === 'private'">
          <v-icon small color="error">mdi-lock-open-remove-outline</v-icon>
        </v-btn>
        <v-btn icon small v-if="item.contact_permission === 'public'">
          <v-icon small color="success">mdi-lock-open-check-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span :title="item.name">
         {{ item.name  }}
      </span>
      </template>

      <template v-slot:[`item.phone`]="{ item }">
      <span :title="item.phone">
         {{ item.phone  }}
      </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   color="primary"
                   small
                   min-width="0"
                   width="30"
                   height="30"
                   class="mx-1 my-1"
                   @click.stop="bind(item)"
            >
              <v-icon color="white" small>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('bind_contact') }}</span>
        </v-tooltip>

      </template>

    </v-data-table>

</template>

<script>
/* Mixins */

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContactBookComponent',
  mixins: [],

  components: {

  },

  data: (vm) => ({
    search: '',
    loading: false,
    headers: [
      { text: vm.$t('name'), value: 'name', sortable: true },
      { text: vm.$t('phone'), value: 'phone', sortable: false },
      { text: vm.$t('type'), value: 'contact_permission', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ],
    options: {
      itemsPerPage: 10
    }

  }),

  computed: {
    ...mapGetters('contactBook', ['getContactBookGetter'])
  },

  methods: {

    ...mapActions('contactBook', ['getContactBookAction']),
    ...mapActions('speedDial', ['setCurrentContactAction']),

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    /**
     * Get contact contacts
     * @returns {Promise<void>}
     */
    async getContactBook () {
      this.loading = true

      const options = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search,
        sort_by: this.options.sortBy[0],
        sort: this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      await this.getContactBookAction(options).then(response => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },

    /**
     * Clear search data
     */
    clearSearch () {
      this.search = ''
      this.getContactBookAction()
    },

    /**
     * Open edit dialog
     * @param item
     * @returns {Promise<void>}
     */
    async bind (item) {
      this.$emit('bindContact', item)
    }

  },

  watch: {
    async search () {
      this.selected = []
      this.options.page = 1
    },
    options: {
      handler () {
        this.getContactBook()
      },
      deep: true
    }
  }

}
</script>

<style scoped>

</style>
