<template>
  <v-btn
      class="text-icon-button"
      :color="toggleGetter ? 'success' : 'grey' "
      large
      dark
      width="100%"
      height="100%"
      :disabled="!callGetter.hasActiveCall"
      @click="toggleMutator(!toggleGetter)"
  >
    <v-icon>{{ recorderButtonIcon}}</v-icon>
    <div class="font-weight-bold pt-2">{{ recorderButtonLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PlaybackButton',
  data: () => ({
    recorderButtonIcon: 'mdi-motion-play',
    recorderButtonLabel: 'Playback'
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    ...mapGetters('recorder', [
      'recordGetter',
      'toggleGetter'
    ])
  },
  methods: {
    ...mapMutations('recorder', [
      'recordMutator',
      'toggleMutator'
    ])
  }
}
</script>

<style scoped>

</style>
