<template>
  <v-btn
      @click="globalHold"
      class="text-icon-button"
      :color="callGetter.canGlobalHold ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      height="100%"
      :disabled="!callGetter.canGlobalHold"
  >
    <v-icon>{{ globalHoldIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ globalHoldLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

/* Mixins */
import globalHoldMixin from '@/mixins/sip/global-hold.mixin'

export default {
  name: 'GlobalHoldButton',
  mixins: [globalHoldMixin],
  data: () => ({
    globalHoldState: false,
    disableGlobalHold: false,
    globalHoldIcon: 'mdi-timer-sand-empty',
    globalHoldLabel: 'Global Hold'
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>
