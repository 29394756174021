import store from '@/store'

export default {
  methods: {
    getName (session) {
      const name = session?._request?.headers?.['P-Asserted-Identity'] || 'NA'
      store.commit('callInformation/nameMutator', name)
    }
  }
}
