import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('global', [
      'hasAnsweredCallGetter'
    ])
  },

  methods: {
    data: () => ({
      keypad: false
    }),

    keyboardActions (action) {
      const keys = {
        KeyC: {
          name: 'Connection',
          hotKey: 'altKey',
          button: 'action_KeyC'
        },
        KeyR: {
          name: 'Registration',
          hotKey: 'altKey',
          button: 'action_KeyR'
        },
        KeyH: {
          name: 'Hold',
          hotKey: 'altKey',
          button: 'action_KeyH'
        },
        KeyM: {
          name: 'Mute',
          hotKey: 'altKey',
          button: 'action_KeyM'
        },
        KeyP: {
          name: 'Parking',
          hotKey: 'altKey',
          button: 'action_KeyP'
        },
        Enter: {
          name: 'Make a call',
          hotKey: 'ctrlKey',
          button: 'button_Enter'
        },
        Space: {
          name: 'Answer the call',
          hotKey: 'ctrlKey',
          method: 'answerCall'
        },
        AltLeft: {
          name: 'Hang up the call',
          hotKey: 'ctrlKey',
          method: 'hangCall'
        },
        Numpad1: {
          name: 'Type 1',
          hotKey: 'ignore',
          button: 'button_Numpad1'
        },
        Numpad2: {
          name: 'Type 2',
          hotKey: 'ignore',
          button: 'button_Numpad2'
        },
        Numpad3: {
          name: 'Type 3',
          hotKey: 'ignore',
          button: 'button_Numpad3'
        },
        Numpad4: {
          name: 'Type 4',
          hotKey: 'ignore',
          button: 'button_Numpad4'
        },
        Numpad5: {
          name: 'Type 5',
          hotKey: 'ignore',
          button: 'button_Numpad5'
        },
        Numpad6: {
          name: 'Type 6',
          hotKey: 'ignore',
          button: 'button_Numpad6'
        },
        Numpad7: {
          name: 'Type 7',
          hotKey: 'ignore',
          button: 'button_Numpad7'
        },
        Numpad8: {
          name: 'Type 8',
          hotKey: 'ignore',
          button: 'button_Numpad8'
        },
        Numpad9: {
          name: 'Type 9',
          hotKey: 'ignore',
          button: 'button_Numpad9'
        },
        Numpad0: {
          name: 'Type 0',
          hotKey: 'ignore',
          button: 'button_Numpad0'
        },
        NumpadAdd: {
          name: 'Keypad +',
          hotKey: 'ignore',
          method: 'typePlus'
        },
        Delete: {
          name: 'Clear Dial Pad',
          hotKey: 'ignore',
          method: 'Delete'
        },
        NumpadSubtract: {
          name: 'Type -',
          hotKey: 'ignore',
          button: 'button_NumpadSubtract'
        },
        NumpadMultiply: {
          name: 'Type *',
          hotKey: 'ignore',
          button: 'button_NumpadMultiply'
        },
        NumpadDivide: {
          name: 'Type #',
          hotKey: 'ignore',
          button: 'button_NumpadDivide'
        },
        Backspace: {
          name: 'Backspace',
          hotKey: 'ignore',
          button: 'button_Backspace'
        }
      }

      if (keys[action.code] && (!!action[keys[action.code].hotKey] || keys[action.code].hotKey === 'ignore')) {
        if (keys[action.code].method === 'answerCall') {
          this.answerCall()
        } else if (keys[action.code].method === 'hangCall') {
          this.hangCall(keys[action.code])
        } else if (keys[action.code].method === 'typePlus') {
          this.longAction({ longClickValue: '+' })
        } else if (keys[action.code].method === 'Delete') {
          this.clearPhoneNumber()
        } else {
          const button = document.getElementById(`${keys[action.code].button}`)
          button.click()
        }
      }
    },

    async checkKeyPad () {
      await navigator.usb.getDevices().then(data => {
        if (data.length) {
          data.find(item => {
            if (item.vendorId === 0x1125 &&
                            item.productId === 0x1808) {
              this.keypad = true
              return true
            }
          })
        } else {
          this.keypad = false
          return false
        }
      })
    },

    answerCall () {
      if (!this.hasAnsweredCallGetter && this.$sip.session.length > 0) {
        this.$sip.session.find(item => {
          if (!item.isEstablished()) {
            const button = document.getElementById(`answer_button_${item._request.call_id}`)
            button.click()
            return true
          }
        })
      }
    },
    hangCall () {
      if (this.hasAnsweredCallGetter) {
        const button = document.getElementById(`hang_up_button_${this.$sip.answeredCallSession.id}`)
        button.click()
      }
    },

    clickToButton (element) {
      const button = document.getElementById(element)
      button.click()
    }
  },
  async mounted () {
    this.$event.listen('keydown', e => {
      this.keyboardActions(e)
    })
  }
}
