<template>
  <v-btn
      class="text-icon-button fill-height full-width"
      :color="rttState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.hasActiveCall"
      height="100%"
  >
    <v-icon>{{ rttIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ rttLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TtyRttTextButton',
  data: () => ({
    disableRtt: false,
    rttState: false,
    rttIcon: 'mdi-deskphone',
    rttLabel: 'TTY/RTT/TEXT'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>
