import apiService from '@/modules/api/csp'
import { XMLParser } from 'fast-xml-parser'

export default function adrXmlParser () {
  return {
    async parse (resource, type) {
      if (!resource) return
      try {
        return await apiService.adrApiModule.get(resource).then(data => {
          return this.parseADR(data?.data, type)
        })
      } catch (e) {
        console.log(e)
      }
    },

    parseADR (data, adrType) {
      const selectedADR = ['Comment', 'SubscriberInfo', 'ServiceInfo', 'DeviceInfo', 'ProviderInfo']

      if (selectedADR.includes(adrType)) {
        const match = new RegExp('EmergencyCallData.' + adrType + '(?=[\\S\\s]{10,8000})[\\S\\s]*EmergencyCallData.' + adrType + '>')
        const ADRMatch = data?.match(match)

        if (ADRMatch && ADRMatch[0]) {
          const parseOptions = { removeNSPrefix: true }
          const parser = new XMLParser(parseOptions)
          return parser.parse(ADRMatch[0])
        }
      }
    }

  }
}
