<template>
  <v-container v-if="layoutGetter" fluid class="fill-height">

    <component :is="layoutGetter.template"></component>

    <dial-pad-button v-if="authUserGetter && $can('chs_can_see_dial_pad')"></dial-pad-button>
    <chat-component :modal="false"></chat-component>
    <live-recording-component/>
    <call-detail-tabs-component/>
  </v-container>
</template>

<script>
/* Views */
import DialPadButton from '@/app/widgets/dial-pad-widget/components/buttons/DialPadButton'
import ChatComponent from '@/app/widgets/chat-widget/components/ChatComponent'
import LiveRecordingComponent from '@/app/widgets/live-recording-widget/components/LiveRecordingComponent'

/* Layout Variants */
import DashboardTemplate1Component from '@/app/shared/layouts/DashboardTemplate1Component'
import DashboardTemplate2Component from '@/app/shared/layouts/DashboardTemplate2Component'
import DashboardTemplate3Component from '@/app/shared/layouts/DashboardTemplate3Component'
import DashboardTemplate4Component from '@/app/shared/layouts/DashboardTemplate4Component'
import DashboardTemplate5Component from '@/app/shared/layouts/DashboardTemplate5Component'

/* Components */
import CallDetailTabsComponent from '@/app/widgets/call-detail-tabs-widget/components/CallDetailTabsComponent'

/* Mixins */
import sipEventsMixin from '@/mixins/sip/sip-events.mixin'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DashboardPage',
  mixins: [sipEventsMixin],
  components: {
    ChatComponent,
    LiveRecordingComponent,
    DialPadButton,
    DashboardTemplate1Component,
    DashboardTemplate2Component,
    DashboardTemplate3Component,
    DashboardTemplate4Component,
    DashboardTemplate5Component,
    CallDetailTabsComponent
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters('global', ['authUserGetter']),
    ...mapGetters('layout', ['layoutGetter'])
  },
  methods: {
    ...mapActions('layout', [
      'loadLayoutAction',
      'loadLayoutsAction'
    ]),
    ...mapActions('settings', [
      'egressAuthAction',
      'getAccountsAction',
      'sipAddressesAction',
      'settingsProxyAction',
      'sipDefaultAddressAction'
    ]),
    ...mapActions('contactBook', [
      'getContactBookAction',
      'getContactGroupsAction'
    ]),
    ...mapActions('speedDial', [
      'getSpeedDialButtonsAction'
    ]),
    ...mapActions('fccMasterRegistry', [
      'getFccMasterRegistryAction'
    ]),
    ...mapActions('logo', [
      'loadLogoAction'
    ])
  },
  async mounted () {
    await this.loadLayoutsAction()
    await this.loadLayoutAction()
    await this.getSpeedDialButtonsAction()
    await this.sipDefaultAddressAction()
    await this.loadLogoAction()

    await this.sipAddressesAction(this.authUserGetter.id)
    await this.settingsProxyAction()

    await this.egressAuthAction()
    await this.getAccountsAction()
  }
}
</script>

<style scoped>

</style>
