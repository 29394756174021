<template>

        <validation-observer ref="observer" >

          <v-form class="pt-4" @submit.prevent="save" novalidate >

            <v-row dense>
              <v-col>
                <validation-provider v-slot="{ errors }" :name="$t('file')" rules="required">
                  <v-file-input
                      v-model="form.file" :error-messages="errors"
                      truncate-length="15"
                      name="file"
                      accept=".xlsx,.xls"
                      label="Choose file"
                      show-size
                      :disabled="disabled"
                      cypress="choice_contact_book_file"
                  ></v-file-input>
                </validation-provider>
              </v-col>
            </v-row>

            <v-container v-if="errors" dense>
              <ul v-for="(item,index) in errors" :key="index" class="pl-0">
                <li v-for="(error,index2) in item" :key="index2" class="red--text text-caption">{{ error }}</li>
              </ul>
            </v-container>

            <v-row dense>
              <v-col class="d-flex flex-row-reverse">
                <v-btn class="chs-dark-btn ml-4" :disabled="disabled" text @click="close()">
                  {{ $t('cancel')}}
                </v-btn>
                <v-btn :disabled="disabled" :loading="disabled" color="error" type="submit" cypress="upload_imported_contacts">
                  {{$t('upload')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>

        </validation-observer>

</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@/rules/validation.rules.js'
import { mapActions } from 'vuex'

import apiService from '@/modules/api/csp'

export default {
  name: 'ImportContactBooksModal',

  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    disabled: false,
    errors: [],
    form: {
      file: null
    }
  }),
  methods: {
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),
    ...mapActions('contactBook', [
      'getContactBookAction'
    ]),

    close () {
      this.$emit('close')
      this.errors = []
    },
    async save () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.disabled = true

        apiService.contactBook.createByFile(this.form).then((response) => {
          this.notificationSnackBarAction({
            show: true,
            color: 'success',
            text: this.$t('contact_imported')
          })

          this.getContactBookAction()

          this.$refs.observer.reset()
          this.form.file = null

          this.close()
          this.$emit('saved')
        }).catch((error) => {
          if (error.response.data.message) {
            this.notificationSnackBarAction({
              show: true,
              color: 'error',
              text: error.response.data.message
            })
          }
          this.errors = error.response.data.errors
          this.$refs.observer.setErrors(error.response.data.errors)
        }).finally(() => {
          this.disabled = false
        })
      }
    }
  }
}
</script>
