<template>
  <v-card flat class="fill-height">
    <v-row dense class="fill-height">
      <v-col cols="12 py-0 d-flex" id="map-container">
        <div
          class="map-holder "
          :class="{
            'map-expaned': mapState === mapStates.expand
          }"
          id="map-holder"
          style="z-index: 5; width: 100%"
        >
          <div class="map-search">
            <label for="map-search-control">Address:</label>
            <input class="map-search-control" type="search" id="map-search-control" />
            <div @click="toggleMap">
              <v-icon>{{ mapState }}</v-icon>
            </div>
          </div>
          <div class="map-content-holder" style="height: calc(100% - 60px);">
            <div class="map-style-switcher">
              <v-switch dense flat inset v-model="isDarkMode" :color="isDarkMode ? colors.shades.white : colors.shades.black" @change="changeMapStyle"></v-switch>
            </div>
            <GmapMap
              ref="mapRef"
              :style="(mapState === 'mdi-fullscreen') ? `width: 100%; height: 100%` : 'width: 100%'"
              :zoom="map.zoom"
              :center="{
                lat: map.lat,
                lng: map.lng
              }"
              map-type-id="terrain"
              :options='{
                panControl: false,
                zoomControl: false,
                scaleControl: false,
                rotateControl: false,
                mapTypeControl: false,
                disableDefaultUI: false,
                fullscreenControl: false,
                streetViewControl: false,
                styles: mapStyles[isDarkMode ? mapStyleTypes.dark : mapStyleTypes.light]
              }'
            >
              <GmapAutocomplete />
              <GmapMarker
                ref="map-marker"
                v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                @click="markerClicked(m, $event)"
                :icon="markerOptions"
              >
                <GmapInfoWindow ref="map-info-window">
                  <span style="color: black">
                    {{ `${index + 1}. ${m.address}` }}
                  </span>
                </GmapInfoWindow>
              </GmapMarker>
            </GmapMap>
          </div>
          <div></div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import colors from 'vuetify/lib/util/colors'
import MapStyle from '../styles/dark.style'
import localStorageService from '@/util/local-storage-service'

import { mapGetters } from 'vuex'

/* Mixins */
import mapLocationMixin from '@/mixins/map-location.mixin'

const mapStates = {
  normal: 'mdi-fullscreen',
  expand: 'mdi-fullscreen-exit'
}

const mapStyleTypes = {
  dark: 'dark',
  light: 'light'
}

export default {
  name: 'MapsTab',
  mixins: [mapLocationMixin],
  data: () => ({
    colors,
    mapState: mapStates.normal,
    mapStates,
    map: {
      lat: 39.8097343,
      lng: -98.5556199,
      zoom: 4.5
    },
    markers: [],
    markerOptions: {
      url: require('@/assets/images/incident.gif')
    },
    mapStyles: MapStyle,
    isDarkMode: true,
    mapStyleTypes
  }),
  computed: {
    ...mapGetters('held', [
      'heldGetter'
    ])
  },
  watch: {
    'heldGetter.lat' (val) {
      this.setLocations(val)
    }
  },
  methods: {
    toggleMap: function () {
      this.mapState = (this.mapState === mapStates.normal ? mapStates.expand : mapStates.normal)

      if (this.mapState === mapStates.expand) {
        document.body.append(document.getElementById('map-holder'))

        return
      }

      document.getElementById('map-container').append(document.getElementById('map-holder'))
    },
    changeMapStyle: function () {
      localStorageService.setMapStyle(this.isDarkMode)
    },
    markerClicked (item, event) {
      console.log(this.markers)
    }
  },
  mounted () {
    this.isDarkMode = (localStorageService.getMapStyle() !== 'false')

    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)

    this.$refs.mapRef.$mapPromise.then((map) => {
      // map.panTo({lat: 1.38, lng: 103.80})

      const input = document.getElementById('map-search-control')
      const searchBox = new window.google.maps.places.SearchBox(input)

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()

        if (places.length === 0) return

        this.$refs.mapRef.panTo(places[0].geometry.location)
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  @import "@/sass/components/map.scss";
</style>
