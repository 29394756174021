import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import heldXmlParser from '@/modules/api/csp/parsers/held/held-xml.parser'

export default function heldParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseGeoLocation(session?._request?.headers?.Geolocation)
    },

    async parse () {
      this.init()
      return {
        held: await this.held()
      }
    },

    async held () {
      return this.emergencyCallDataParser?.location_url ? await heldXmlParser().parse(this.emergencyCallDataParser?.location_url, 'Comment') : null
    }
  }
}
