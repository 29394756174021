<template>
  <v-row dense class="fill-height">
    <!-- Left sidebar -->

    <v-col cols="12" class="fill-height">
      <v-row dense>
        <v-col cols="3">
          <placeholder-component :components="layoutGetter.slots.slot_1" />
        </v-col>
        <v-col cols="9">
          <placeholder-component :components="layoutGetter.slots.slot_2" />
        </v-col>
      </v-row>
      <v-row dense class="fill-height top-section" style="height: 46%">
        <!-- Top left view -->
        <v-col cols="3" class="fill-height">

          <placeholder-component :components="layoutGetter.slots.slot_3" />

        </v-col>
        <!-- Top middle view -->
        <v-col class="fill-height">

          <placeholder-component :components="layoutGetter.slots.slot_4" />

        </v-col>
        <!-- Top right view -->
        <v-col class="fill-height">

          <placeholder-component :components="layoutGetter.slots.slot_5" />

        </v-col>
      </v-row>
      <v-row dense class="fill-height " style="height: 43%">
        <!-- Bottom left view -->
        <v-col cols="4">
          <placeholder-component :components="layoutGetter.slots.slot_6" />
        </v-col>
        <!-- Bottom middle view -->
        <v-col cols="4">
          <placeholder-component :components="layoutGetter.slots.slot_7" />

        </v-col>
        <!-- Bottom right view -->
        <v-col cols="4">
          <placeholder-component :components="layoutGetter.slots.slot_8" />

        </v-col>
      </v-row>
    </v-col>

  </v-row>
</template>

<script>

import PlaceholderComponent from '@/app/components/widget-placeholder/components/WidgetPlaceholderComponent'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardTemplate4Component',
  components: {
    PlaceholderComponent
  },
  computed: {
    ...mapGetters('layout', ['layoutGetter'])
  }
}
</script>

<style scoped>

</style>
