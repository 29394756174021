<template>

    <v-data-table
      class="px-4 py-2"
      :headers="headers"
      :items="items.value || []"
      :search="search"
      :options.sync="options"
      item-key="id"
      selectable-key="id"
      single-select
      :loading-text="$t('loading')"
      dense
      :items-per-page="10"
      :dark="$vuetify.theme.dark"
      ref="parent-height"
      :height="fixedHeight -  150"
      show-expand
      single-expand
      :expanded="expanded"
      cypress="contact_book_table"
    >
      <template v-slot:[`top`]>
        <div>
          <div class="d-flex" >

            <DialogShared v-model="contactGroupsDialog" eager max-width="800" :title="$t('contact_groups')">
              <ContactGroupsComponent @close="contactGroupsDialog=false"></ContactGroupsComponent>
            </DialogShared>

            <DialogShared v-model="importContactsDialog" eager max-width="800" :title="$t('import_contacts')">
              <ImportContactBooks @close="importContactsDialog=false"></ImportContactBooks>
            </DialogShared>
            <DialogShared v-model="dialog.open" eager max-width="800" :title="dialog.title">

              <validation-observer ref="observer" >

                <v-card tile flat color="secondary_card">
                  <v-card-text>
                    <v-form ref="form" :disabled="loading" @submit.prevent="onSubmit" novalidate>

                      <v-row class="pb-4">
                        <v-col cols="12" class="text-right pt-2">
                          <v-btn @click="addRow" :rounded="true" small color="success" cypress="add_new_number">
                            Add new number
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <validation-provider v-slot="{ errors }" :name="$t('contact_permission')" rules="required">
                        <v-select :error-messages="errors"
                                  v-model="form.contact_permission"
                                  :items="contactPermOptions"
                                  outlined
                                  name="contact_permission"
                                  item-text="label"
                                  item-value="value"
                                  :label="$t('contact_permission')"
                        ></v-select>
                      </validation-provider>

                      <validation-provider v-slot="{ errors }" :name="$t('contact_groups')" rules="">
                        <v-select :dark="$vuetify.theme.dark" :error-messages="errors"
                                  v-model="form.contact_book_group_ids"
                                  :items="getContactGroupsGetter"
                                  outlined
                                  multiple small-chips
                                  name="contact_groups"
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('contact_groups')"

                        ></v-select>
                      </validation-provider>

                      <v-radio-group v-model="form.is_primary">
                        <template v-for="(item, index) in form.contact_book_phones">
                        <v-sheet
                          color="transparent"
                          elevation="3"
                          class="pa-4 mt-2"
                          :rounded="true"
                          :key="index"
                        >
                          <v-row>
                            <v-col cols="8" class="pb-0">
                              <v-radio
                                :key="index"
                                :value="index"
                                label="Make primary"
                                class="my-0"
                                @click="changePrimary()"
                                :cypress="`contact_book_primary_${index}`"
                              ></v-radio>
                            </v-col>
                            <v-col cols="4" class="text-right pb-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn @click="removeRow(index)" icon v-on="on">
                                    <v-icon color="error">
                                      mdi-minus
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>Remove phone number</span>
                              </v-tooltip>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                              <validation-provider v-slot="{ errors }"  :name="$t('phone_number')" rules="required">
                                <v-text-field :error-messages="errors"
                                              v-model="item.phone"
                                              name="phone"
                                              :label="$t('phone_number')"
                                              required
                                              outlined
                                              :cypress="`contact_phone_number_${index}`"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="6" class="pb-0">
                              <validation-provider v-slot="{ errors }" :name="$t('name')" rules="required">
                                <v-text-field :error-messages="errors"
                                              v-model="item.name"
                                              name="name"
                                              :label="$t('name')"
                                              required
                                              outlined
                                              :cypress="`contact_name_${index}`"
                                ></v-text-field>
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </template>
                      </v-radio-group>

                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="mr-5" color="passive_button" :disabled="loading"  @click="onCancel()">
                      {{ $t('cancel')}}
                    </v-btn>
                    <v-btn @click="onSubmit" :disabled="loading" color="error" cypress="submit_contact">
                      {{ $t('save')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>

              </validation-observer>

            </DialogShared>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn :disabled="!!!$can('chs_can_add_contact')" depressed text fab small class="mt-3" @click="create" v-on="on" cypress="add_new_contact">
                  <v-icon color="error">
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('add_new_contact') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn  :disabled="!!!$can('chs_can_add_contact')" depressed text fab small class="mt-3" @click="onContactGroupClick" v-on="on" cypress="create_new_contact_group">
                  <v-icon color="error">
                    mdi-account-group
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('contact_groups') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="onImportClick" v-if="$can('chs_can_import_contacts')" v-on="on" depressed text fab small class="mt-3" icon cypress="import_contacts">
                  <v-icon color="error">
                    mdi-database-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('import_contacts') }}</span>
            </v-tooltip>
            <div class="vertical-divider"></div>
            <v-text-field :disabled="loading" @keyup.enter="getContactBook" v-model="search" clearable @click:clear="clearSearch"
                          class="mr-8"
                          prepend-icon="mdi-magnify"
                          :label="$t('search')"
                          cypress="contact_search"
            >

              <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading" @click="getContactBook()" cypress="contact_find">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

            </v-text-field>

          </div>
          <div >
            <v-chip small @click.prevent="removeGroupFilter" class="mb-1 mr-1" v-if="getContactGroupsGetter.length > 0 ">
              {{ $t('all')}}
            </v-chip>
            <v-chip :style="{ 'background-color': group.colour }" class="mr-1 pr-1 pl-1 mb-1" small  v-for="group in getContactGroupsGetter" :key="group.id"  @click.prevent="filterContacts(group)"  >
              <v-icon small v-if="group.icon">{{group.icon}}</v-icon>{{ group.name | substringIf}}
            </v-chip>
          </div>
        </div>

      </template>

      <template v-slot:[`item.contact_permission`]="{ item }">
        <v-btn icon small v-if="item.contact_permission === 'private'">
          <v-icon small color="error">mdi-lock-open-remove-outline</v-icon>
        </v-btn>
        <v-btn icon small v-if="item.contact_permission === 'public'">
          <v-icon small color="success">mdi-lock-open-check-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-btn color="grey" small depressed min-width="0" width="30" height="30" class="mx-1 my-1">
          <v-icon class="grey" small>
            mdi-account-box-outline
          </v-icon>
        </v-btn>
        <span :title="item.phone.name">
         {{ item.phone.name | substringIf(0,10,'...') }}
        </span>
      </template>

      <template v-slot:[`item.phone`]="{ item }">
        <span :title="item.phone.phone">
           {{ item.phone.phone | substringIf(0,20,'...') }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item, index }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   color="primary"
                   small
                   min-width="0"
                   width="30"
                   height="30"
                   class="mx-1 my-1"
                   @click.stop="edit(item)"
                   :cypress="`contact_book_edit_${index}`"
            >
              <v-icon color="white" small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   color="error"
                   small
                   min-width="0"
                   width="30"
                   height="30"
                   class="mx-1 my-1"
                   @click.stop="remove(item.id)"
                   :cypress="`contact_book_remove_${index}`"
            >
              <v-icon color="white" small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('delete') }}</span>
        </v-tooltip>

        <call-back-button :dialNumber="item.phone.phone"/>
        <call-transfer-button :dialNumber="item.phone.phone"/>

      </template>

      <template v-slot:expanded-item="{ item }">
        <td colspan="5" class="pa-0">
          <v-simple-table fixed-header class="expanded-table">
            <tbody>
            <template v-for="phones in item.contact_book_phones">
              <tr class="pa-0" v-if="!phones.is_primary" :key="phones.id">
                <td>
                  Name:
                  <span :title="phones.name">
                    {{ phones.name | substringIf(0,15,'...') }}
                  </span>
                </td>
                <td>
                  Phone:
                  <span :title="phones.phone">
                    {{ phones.phone | substringIf(0,15,'...') }}
                  </span>
                <td>
                  <call-back-button :dialNumber="phones.phone"/>
                  <call-transfer-button :dialNumber="phones.phone"/>
                </td>
              </tr>
            </template>
            </tbody>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
import { mapActions, mapGetters } from 'vuex'

import DialogShared from '@/app/shared/dialog/DialogShared'
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/rules/validation.rules.js'
import ImportContactBooks from '@/app/widgets/contact-book-widget/components/ImportContactBooks'
import ContactGroupsComponent from '@/app/widgets/contact-book-widget/components/ContactGroupsComponent'

export default {
  name: 'ContactBookComponent',
  mixins: [parentHeight],

  components: {
    ContactGroupsComponent,
    ImportContactBooks,
    DialogShared,
    ValidationObserver,
    ValidationProvider,

    CallBackButton,
    CallTransferButton
  },

  data: (vm) => ({
    search: '',
    expanded: [],
    group_id: null,
    loading: false,
    headers: [
      { text: vm.$t('name'), value: 'name', sortable: true },
      { text: vm.$t('phone'), value: 'phone', sortable: false },
      { text: vm.$t('type'), value: 'contact_permission', sortable: false },
      { text: vm.$t('actions'), value: 'actions', sortable: false }
    ],
    options: {
      itemsPerPage: 10,
      sortDesc: ['desc']
    },
    dialog: {
      open: false,
      title: ''
    },
    contactGroupsDialog: false,
    importContactsDialog: false,
    form: {
      id: null,
      contact_permission: 'public',
      contact_book_group_ids: [],
      phone: '',
      name: '',
      note: '',
      icon: '',
      tandem_transfer: '',
      category: '',
      '911_text_transfer': '',
      quick_dial_harris: ''
    },

    contactPermOptions: [
      {
        value: 'public',
        label: 'Public'
      },
      {
        value: 'private',
        label: 'Private'
      }
    ],
    items: {
      value: []
    }
  }),

  computed: {
    ...mapGetters('contactBook', ['getContactBookGetter', 'getContactGroupsGetter'])
  },

  methods: {

    ...mapActions('contactBook', ['createContactBookAction', 'getContactBookAction', 'deleteContactBookAction', 'updateContactBookAction']),

    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    /**
     * Get contact contacts
     * @returns {Promise<void>}
     */
    async getContactBook () {
      this.loading = true
      const options = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search,
        sort_by: this.options.sortBy[0],
        sort: this.options.sortDesc[0] ? 'desc' : 'asc',
        group_id: this.group_id
      }

      this.items = await this.$localCacher.indexDB.contactBooks.get()

      await this.getContactBookAction(options).then(response => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },

    /**
     * Clear search data
     */
    clearSearch () {
      this.search = ''
      this.getContactBookAction()
    },

    /**
     * Open create dialog
     * @returns {Promise<void>}
     */
    async create () {
      this.$refs.observer.reset()
      this.dialog.title = this.$t('create_contact')
      this.dialog.open = true

      this.form = {
        id: null,
        contact_permission: 'public',
        contact_book_group_ids: [],
        phone: '0',
        name: '',
        note: '',
        icon: '',
        tandem_transfer: '',
        category: '',
        '911_text_transfer': '',
        quick_dial_harris: '',
        is_primary: 0,
        contact_book_phones: [{
          phone: '',
          name: '',
          is_primary: true
        }]
      }
    },

    async onImportClick () {
      this.$refs.observer.reset()
      this.importContactsDialog = true
    },
    async onContactGroupClick () {
      this.$refs.observer.reset()
      this.contactGroupsDialog = true
    },

    /**
     * Open edit dialog
     * @param item
     * @returns {Promise<void>}
     */
    async edit (item) {
      this.$refs.observer.reset()

      this.form = JSON.parse(JSON.stringify(item))
      this.form.is_primary = this.form.contact_book_phones.findIndex(phones => phones.is_primary === true)

      this.dialog.title = this.$t('update_contact')
      this.dialog.open = true
    },

    /**
     * Remove contact
     * @param id
     * @returns {Promise<void>}
     */
    async remove (id) {
      this.$confirm(this.$t('please_confirm_that_you_want_to_delete_this', { what: this.$t('contact') })).then(async confirmed => {
        if (confirmed) {
          await this.deleteContactBookAction({ id })
        }
      })
    },

    removeGroupFilter () {
      this.group_id = null
      this.getContactBook()
    },
    filterContacts (group) {
      this.group_id = group.id
      this.getContactBook()
    },

    onCancel () {
      this.dialog.open = false
      this.$refs.observer.reset()
    },

    /**
     * Submit form
     * @returns {Promise<void>}
     */
    async onSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        if (this.form.id) {
          await this.updateContactBookAction({
            id: this.form.id,
            data: this.form
          }).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('contact_updated')
            })
            this.loading = false
            this.dialog.open = false
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        } else {
          await this.createContactBookAction({
            ...this.form
          }).then((response) => {
            this.notificationSnackBarAction({
              show: true,
              color: 'success',
              text: this.$t('contact_created')
            })
            this.loading = false
            this.dialog.open = false
          }).catch((error) => {
            this.$refs.observer.setErrors(error.response.data.errors)
            this.loading = false
          })
        }
      }
    },

    call (number) {

    },
    transferCall (number) {},
    addRow () {
      this.form.contact_book_phones.push({
        phone: '',
        name: '',
        is_primary: false
      })
    },
    removeRow (index) {
      if (this.form.contact_book_phones.length > 1) {
        if (this.form.is_primary === index) {
          this.form.contact_book_phones.splice(index, 1)
          this.form.contact_book_phones[0].is_primary = true
          this.form.is_primary = 0
        } else {
          this.form.contact_book_phones.splice(index, 1)
          this.form.is_primary = this.form.contact_book_phones.findIndex(phones => phones.is_primary === true)
        }
      }
    },
    changePrimary () {
      this.form.contact_book_phones.forEach(item => { item.is_primary = false })
      this.form.contact_book_phones[this.form.is_primary].is_primary = true
    }
  },

  watch: {
    async search () {
      this.selected = []
      this.options.page = 1
    },
    options: {
      handler () {
        this.getContactBook()
      },
      deep: true
    }
  }

}
</script>

<style scoped>

.expanded-table td{
  font-size: 0.75rem !important;
  height: 30px !important;;
}
.expanded-table td:last-child{
  width: 210px !important;
}
</style>
