import store from '@/store'
import sipHelper from '@/helpers/sip.helper'
export default {
  methods: {
    warmTransfer (target) {
      this.$sip.answeredCallSession.refer(sipHelper.getTarget(target, this.$sip.domain), {
        eventHandlers: {
          requestSucceeded: this.requestSucceeded,
          requestFailed: this.requestFailed,
          trying: this.trying,
          progress: this.progress,
          accepted: this.accepted,
          failed: this.failed
        }
      })
    },
    async requestSucceeded (e) {
      store.commit('global/callMutator', { type: 'transferring', data: false })
      this.$event.dispatch('transferSucceed', e)
      await this.displayNotification({ color: 'success', text: 'Request succeeded!' })
      console.log('requestSucceeded', e)
    },
    async requestFailed (e) {
      store.commit('global/callMutator', { type: 'transferring', data: false })
      this.$event.dispatch('transferRequestFailed', e)
      await this.displayNotification({ color: 'error', text: 'Request failed!' })
      console.log('requestFailed', e)
    },
    async trying (e) {
      this.$event.dispatch('transferTrying', e)
      await this.displayNotification({ color: 'warning', text: 'Trying to transfer!' })
      console.log('trying', e)
    },
    async progress (e) {
      this.$event.dispatch('transferProgress', e)
      await this.displayNotification({ color: 'warning', text: 'Transfer in progress!' })
      console.log('progress', e)
    },
    async accepted (e) {
      store.commit('global/callMutator', { type: 'transferring', data: false })
      this.$event.dispatch('transferAccepted', e)
      await this.displayNotification({ color: 'success', text: 'Transfer accepted!' })
      console.log('accepted', e)
    },
    async failed (e) {
      store.commit('global/callMutator', { type: 'transferring', data: false })
      this.$event.dispatch('transferFailed', e)
      await this.displayNotification({ color: 'error', text: 'Request failed!' })
      console.log('failed', e)
    },
    async displayNotification (data) {
      await store.dispatch('notifications/notificationSnackBarAction', {
        color: data.color,
        icon: 'mdi-transfer',
        text: data.text,
        show: true,
        timeout: 5000
      })
    }
  }
}
