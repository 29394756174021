<template>
  <call-line-component :lines="8" :title="$t('emergency_calls')" color="error" type="emergency" />
</template>

<script>
/* Components */
import CallLineComponent from '@/app/widgets/call-line-widget/components/CallLineComponent'
export default {
  name: 'EmergencyCallLineComponent',
  components: {
    CallLineComponent
  }
}
</script>

<style lang="scss">

</style>
