<template>
  <v-data-table
    class="px-4 py-2"
    :headers="headers"
    :items="getFccMasterRegistryGetter.data"
    :search="search"
    :options.sync="options"
    item-key="id"
    selectable-key="id"
    single-select
    :server-items-length="getFccMasterRegistryGetter.meta.total"
    dense
    :dark="$vuetify.theme.dark"
    :height="fixedHeight - 150"
    ref="parent-height"
    :loading="loading"
    :loading-text="$t('loading')"
    :footer-props="{itemsPerPageOptions : [5,10,15]}"

  >

    <template v-slot:[`top`]>

      <v-text-field :disabled="loading"
                    @keyup.enter="getFccMasterRegistry"
                    v-model="search" clearable @click:clear="clearSearch"
                    :label="$t('search')"
      >
        <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading" @click="getFccMasterRegistry()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

      </v-text-field>
    </template>

>

    <template v-slot:[`item.last_modified`]="{ item }">
      {{ item.last_modified | dateFormatter }}
    </template>

  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'

/* Vuex */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FccMasterRegistryComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {

  },
  data: (vm) => ({
    search: '',
    loading: false,
    headers: [
      { text: vm.$t('psap_id'), value: 'psap_id', sortable: false },
      { text: vm.$t('psap_name'), value: 'psap_name', sortable: false },
      { text: vm.$t('state'), value: 'state', sortable: false },
      { text: vm.$t('county'), value: 'county', sortable: false },
      { text: vm.$t('city'), value: 'city', sortable: false }
    ],
    totalItems: 0,
    items: [],
    options: {
      itemsPerPage: 15
    }
  }),

  computed: {
    ...mapGetters('fccMasterRegistry', ['getFccMasterRegistryGetter'])
  },

  methods: {

    ...mapActions('fccMasterRegistry', ['getFccMasterRegistryAction']),

    async getFccMasterRegistry () {
      this.loading = true
      const options = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.search
      }

      await this.getFccMasterRegistryAction(options).then(response => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    clearSearch () {
      this.search = ''
      this.getFccMasterRegistry()
    }
  },

  watch: {
    async search () {
      this.selected = []
      this.options.page = 1
    },
    options: {
      handler () {
        this.getFccMasterRegistry()
      },
      deep: true
    }

  },
  async created () {

  },
  async mounted () {
    await this.getFccMasterRegistry()
  }
}
</script>

<style scoped>

</style>
