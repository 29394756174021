<template>
  <v-btn
      class="text-icon-button"
      :color="irrState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.hasActiveCall"
      height="100%"
  >
    <v-icon>{{ irrIcon }}</v-icon>
    <div class="font-weight-bold pt-2">IRR Instant <br/> Retrieval</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IrrInstantButton',
  data: () => ({
    disableIrr: false,
    irrState: false,
    irrIcon: 'mdi-microphone-off'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>
