<template>
  <v-card flat class="fill-height pa-2">
    <v-row no-gutters class="fill-height">
      <template v-for="(item, index) in computedInfo">
        <v-col align-self="center" class="text-truncate" :key="index" cols="12">
          <span class="font-weight-bold">
            {{ item.title }}
          </span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ item.value }}</span>
                  </template>
                  <span>{{ item.value }}</span>
          </v-tooltip>
        </v-col>
      </template>
      <v-col align-self="end" cols="12">
        <v-row dense>
          <v-col>
            <held-rebid-button/>
          </v-col>
          <v-col>
            <clear-call-data-button/>
          </v-col>
          <v-col>
            <incorrect-location-button/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
/* Buttons */
import HeldRebidButton from '@/app/widgets/call-information-widget/components/buttons/HeldRebidButton'
import ClearCallDataButton from '@/app/widgets/call-information-widget/components/buttons/ClearCallDataButton'
import IncorrectLocationButton from '@/app/widgets/call-information-widget/components/buttons/IncorrectLocationButton'
export default {
  name: 'CallInfoComponent',
  components: {
    HeldRebidButton,
    ClearCallDataButton,
    IncorrectLocationButton
  },
  computed: {
    ...mapGetters('adr', [
      'commentGetter',
      'deviceInfoGetter',
      'providerInfoGetter',
      'serviceInfoGetter',
      'subscriberInfoGetter'
    ]),
    ...mapGetters('held', [
      'heldGetter'
    ]),
    ...mapGetters('callInformation', [
      'dateTimeGetter',
      'esrkNumberGetter',
      'nameGetter'
    ]),
    computedInfo () {
      return [
        { title: 'Date/Time:', value: this.dateTimeGetter },
        { title: 'Provider:', value: this.providerInfoGetter.DataProviderString },
        { title: 'Callback Number:', value: this.subscriberInfoGetter.SubscriberData.vcard.tel.uri },
        { title: 'ESRK Number:', value: this.esrkNumberGetter },
        { title: 'Class of Service:', value: this.serviceInfoGetter.ServiceType },
        { title: 'Name:', value: this.nameGetter },
        { title: 'Address:', value: this.getAddress() },
        { title: 'City, ST Zip:', value: this.getCityZip() },
        { title: 'Lat/Lon:', value: this.getLatLng() },
        { title: 'Cell/Sector ID:', value: this.heldGetter.LOC },
        { title: 'Comments:', value: this.commentGetter.Comment }
      ]
    }
  },
  methods: {
    getAddress () {
      return `${this.heldGetter.civicAddress.RD || ''} ${this.heldGetter.civicAddress.HNO || ''} ${this.heldGetter.civicAddress.country || ''}`
    },
    getCityZip () {
      return `${this.heldGetter.civicAddress.A3 || ''} ${this.heldGetter.civicAddress.A1 || ''} ${this.heldGetter.civicAddress.PC || ''}`
    },
    getLatLng () {
      return `${this.heldGetter.lat || ''} ${this.heldGetter.lng || ''}`
    }
  }
}
</script>

<style scoped>

</style>
