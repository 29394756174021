<template>
  <v-data-table
    class="px-4 py-2"
    :headers="headers"
    :items="items.value || []"
    :search="search"
    :options.sync="options"
    item-key="id"
    selectable-key="id"
    single-select
    dense
    :dark="$vuetify.theme.dark"
    :height="fixedHeight - 150"
    ref="parent-height"
    :loading="loading"
    :loading-text="$t('loading')"
    :footer-props="{itemsPerPageOptions : [5,10,15]}"
  >

    <template v-slot:[`top`]>

      <v-text-field :disabled="loading"
                    @keyup.enter="getCallHistory"
                    v-model="search" clearable @click:clear="clearSearch"
                    :label="$t('search')"
      >
        <v-btn slot="append-outer" color="primary" elevation="0" :disabled="loading" @click="getCallHistory()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

      </v-text-field>
    </template>

    <template v-slot:[`item.from`]="{ item }">
      {{ item.call_from }}
    </template>

    <template v-slot:[`item.datetime`]="{ item }">
      {{ item.created_at | dateFormatter }}
    </template>

    <template v-slot:[`item.call_direction`]="{ item }">
      <v-icon v-if="item.call_direction === 'incoming'" color="success">
        mdi-phone-incoming
      </v-icon>
      <v-icon v-if="item.call_direction === 'outgoing'" color="accent">
        mdi-phone-outgoing
      </v-icon>
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <call-back-button :dialNumber="item.call_from"/>
      <call-transfer-button :dialNumber="item.call_from"/>
      <call-detail-button :data="item"/>
    </template>

  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'
/* Buttons */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import CallDetailButton from '@/app/shared/buttons/CallDetailButton'

/* Vuex */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CallHistoryComponent',
  mixins: [parentHeight],
  props: ['parentElement'],
  components: {
    CallBackButton,
    CallTransferButton,
    CallDetailButton
  },
  data: (vm) => ({
    search: '',
    loading: false,
    headers: [
      { text: '', value: 'call_direction', sortable: false, width: '3%' },
      { text: vm.$t('number'), value: 'from', sortable: false },
      { text: vm.$t('date'), value: 'datetime', sortable: false },
      { text: vm.$t('action'), value: 'action', sortable: false }
    ],
    totalItems: 0,
    items: [],
    options: {
      itemsPerPage: 15
    }
  }),

  computed: {
    ...mapGetters('callHistory', ['getHistoryGetter'])
  },

  methods: {

    ...mapActions('callHistory', ['getCallHistoryAction']),

    async getCallHistory () {
      this.loading = true
      const options = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        call_from: this.search
      }

      await this.getCallHistoryAction(options).then(response => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    clearSearch () {
      this.search = ''
      this.getCallHistory()
    }
  },

  watch: {
    async search () {
      this.selected = []
      this.options.page = 1
    },
    options: {
      handler () {
        this.getCallHistory()
      },
      deep: true
    }

  },
  async created () {
    this.items = await this.$localCacher.indexDB.callHistory.get()
  },
  mounted () {
    this.$event.listen('refreshCallHistory', async () => {
      await this.getCallHistory()
    })
  }
}
</script>

<style scoped>

</style>
