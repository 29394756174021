<template>
    <div :class="{ 'fill-height' : true, 'box' : buttons}" >
      <component :class="{'ma-1' : buttons}" :is="comp" v-for="(comp, key) in components" :key="key"/>
    </div>
</template>

<script>

import CallControlComponent from '@/app/widgets/call-control-widget/components/CallControlComponent'

import CallHistoryComponent from '@/app/widgets/call-history-widget/components/CallHistoryComponent'
import CallHistoryTabsComponent from '@/app/widgets/call-history-tabs-widget/components/CallHistoryTabsComponent'
import EmergencyCallTabsComponent from '@/app/widgets/emergency-call-tabs-widget/components/EmergencyCallTabsComponent'
import SpeedDialTabsComponent from '@/app/widgets/speed-dial-tabs-widget/components/SpeedDialTabsComponent'
import LogoAndClockComponent from '@/app/widgets/logo-clock-widget/components/LogoAndClockComponent'
import EmergencyCallLineComponent from '@/app/widgets/emergency-call-widget/components/EmergencyCallComponent'
import AdminCallComponent from '@/app/widgets/admin-call-widget/components/AdminCallComponent'
import MapsTab from '@/app/widgets/map-widget/components/MapComponent'

/* Single Components */
import LogoComponent from '@/app/widgets/logo-widget/components/LogoComponent'
import ClockComponent from '@/app/widgets/clock-widget/components/ClockComponent'

/* Buttons */

import ReadyStateButton from '@/app/widgets/call-control-widget/components/buttons/ReadyStateButton'
import LocalHoldButton from '@/app/widgets/call-control-widget/components/buttons/LocalHoldButton'
import GlobalHoldButton from '@/app/widgets/call-control-widget/components/buttons/GlobalHoldButton'
import MuteButton from '@/app/widgets/call-control-widget/components/buttons/MuteButton'
import PlaybackButton from '@/app/widgets/call-control-widget/components/buttons/PlaybackButton'
import HangUpButton from '@/app/widgets/call-control-widget/components/buttons/HangUpButton'
import ConferenceButton from '@/app/widgets/call-control-widget/components/buttons/ConferenceButton'
import TransferButton from '@/app/widgets/call-control-widget/components/buttons/TransferButton'
import TtyRttTextButton from '@/app/widgets/call-control-widget/components/buttons/TtyRttTextButton'
import IrrInstantButton from '@/app/widgets/call-control-widget/components/buttons/IrrInstantButton'
import IrrPlaybackButton from '@/app/widgets/call-control-widget/components/buttons/IrrPlaybackButton'
import IrrVoiceButton from '@/app/widgets/call-control-widget/components/buttons/IrrVoiceButton'

import DialPadComponent from '@/app/widgets/dial-pad-widget/components/DialPadComponent'

export default {
  name: 'WidgetPlaceholderComponent',

  components: {
    CallControlComponent,
    CallHistoryComponent,
    CallHistoryTabsComponent,
    EmergencyCallTabsComponent,
    SpeedDialTabsComponent,
    LogoAndClockComponent,
    EmergencyCallLineComponent,
    AdminCallComponent,
    MapsTab,

    LogoComponent,
    ClockComponent,

    ReadyStateButton,
    LocalHoldButton,
    GlobalHoldButton,
    MuteButton,
    PlaybackButton,
    HangUpButton,
    ConferenceButton,
    TransferButton,
    TtyRttTextButton,
    IrrInstantButton,
    IrrPlaybackButton,
    IrrVoiceButton,

    DialPadComponent
  },

  props: {
    components: {
      type: [Array, String]
    },
    buttons: {
      type: [Boolean],
      default: false
    }
  },

  data: (vm) => ({

  }),

  async mounted () {

  }
}
</script>

<style scoped lang="scss">

.box{
  display: flex;
  > * {
    flex: 1 1 0;
  }

}

</style>
