<template>
  <v-data-table
    class="px-4 py-2"
    :headers="headers"
    :loading=loading
    :items="items.value || []"
    item-key="id"
    selectable-key="id"
    single-select
    :items-per-page="itemPerPage"
    show-expand
    single-expand
    loading-text="Loading... Please wait"
    dense
    :expanded="expanded"
    @click:row="handleSelected"
    :dark="$vuetify.theme.dark"
    ref="parent-height"
    :height="fixedHeight - 80"
  >

    <template v-slot:[`item.full_name`]="{ item }">
      {{ item.first_name }} {{ item.last_name }}
    </template>

    <template v-slot:[`item.dial`]="{ item }">
      <call-back-button :dialNumber="item.presence_id"/>
      <call-transfer-button :dialNumber="item.presence_id"/>
      <observe-button :dialNumber="item.presence_id"/>
      <whisper-button :dialNumber="item.presence_id"/>
      <barge-button :dialNumber="item.presence_id"/>
    </template>

  </v-data-table>
</template>

<script>
/* Mixins */
import parentHeight from '@/mixins/parent-height.mixin'

/* Shared */
import CallBackButton from '@/app/shared/buttons/CallBackButton'
import CallTransferButton from '@/app/shared/buttons/CallTransferButton'
import ObserveButton from '@/app/shared/buttons/ObserveButton'
import WhisperButton from '@/app/shared/buttons/WhisperButton'
import BargeButton from '@/app/shared/buttons/BargeButton'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AgentsComponent',
  mixins: [parentHeight],
  components: {
    CallBackButton,
    CallTransferButton,
    ObserveButton,
    WhisperButton,
    BargeButton
  },
  data: (vm) => ({
    loading: false,
    itemPerPage: 10,
    expanded: [],
    selected: [],
    headers: [
      { text: 'Full Name', value: 'full_name', sortable: false },
      { text: 'Extension', value: 'presence_id', sortable: false },
      { text: 'Dial', value: 'dial', sortable: false }
    ],
    items: {
      value: []
    }
  }),
  computed: {
    ...mapGetters('settings', ['sipDefaultAddressGetter']),
    ...mapGetters('callAgents', ['callAgentsGetter'])
  },
  watch: {
    'sipDefaultAddressGetter.account_id' (val) {
      this.getUsers(val)
    }
  },
  methods: {
    ...mapActions('callAgents', ['callAgentsAction']),
    handleSelected (payload) {
      this.selected = [payload]
    },
    async getUsers (accountId) {
      this.loading = true
      await this.callAgentsAction({
        accountId: accountId,
        params: {}
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    }
  },
  async mounted () {
    this.items = await this.$localCacher.indexDB.agents.get()
  }
}
</script>

<style scoped>

</style>
