export default {
  methods: {
    getNestedObject (callInfoObject, type, translate) {
      for (const key in callInfoObject) {
        const val = callInfoObject[key]
        if (typeof val === 'object') {
          this.getNestedObject(val, type, translate)
        } else {
          if (val && val !== 'NA') {
            const item =
              {
                name: (translate) ? this.$t(key.toString()) : key.toString(),
                value: val.toString()
              }
            this[type].push(item)
          }
        }
      }
    }
  }
}
