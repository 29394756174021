<template>
  <v-card flat class="fill-height">
    <v-row dense class="fill-height pb-3 px-3">
      <v-col cols="12" align-self="center" @click="startFirstCall">
        <v-alert
          class="mb-0 text-uppercase"
          text
          dense
          :type="color"
          icon="mdi-phone"
        >
          {{ title }}
        </v-alert>
      </v-col>
      <v-col
        v-for="(n, i) in lines"
        :key="n"
        cols="6"
      >
        <call-line-button ref="call-line" :index="i" :number="n" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
/* Buttons */
/* dasd as */
import CallLineButton from '@/app/widgets/call-line-widget/components/buttons/CallLineButton'
export default {
  name: 'CallLineComponent',
  props: ['title', 'color', 'lines', 'type'],
  components: {
    CallLineButton
  },
  methods: {
    start (session) {
      this.$refs['call-line'].some(item => {
        if (!item.$data.active && session.status === 4) {
          item.$data.session = session
          item.$data.active = true
          return true
        }
      })
    },
    end (session) {
      this.$refs['call-line'].some(item => {
        if (item.$data?.session?.id === session?.id) {
          item.$data.session = null
          item.$data.adr = null
          item.$data.active = false
          return true
        }
      })
    },
    startFirstCall () {
      if (this.type !== 'emergency') return

      if (!this.$refs['call-line'][0]) return

      this.$refs['call-line'][0].answerCall()
    }
  },
  mounted () {
    this.$event.listen('newAdminCall', (e) => {
      if (e.eventData.type === this.type) {
        switch (e.eventData.event.session.status) {
          case 4:
            this.start(e.eventData.event.session)
            break
          case 7:
          case 8:
            this.end(e.eventData.event.session)
            break
        }
      }
    })
  }
}
</script>

<style scoped>

</style>
