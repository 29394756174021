export default {
  methods: {
    getCallerStream () {
      return document.getElementById('caller-audio').captureStream()
    },
    async getMicrophoneStream () {
      return await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        return stream
      })
    },
    async startRecording () {
      this.reset()
      await this.getMicrophoneStream().then(stream => {
        // Get outgoing media stream from the mic
        const outgoingStream = new MediaStream()
        outgoingStream.addTrack(stream?.getAudioTracks()?.[0])
        // Get incoming media stream from audio track
        const incomingStream = new MediaStream()
        incomingStream.addTrack(this.getCallerStream()?.getAudioTracks()?.[0])
        // Create audio context
        const audioContext = new AudioContext()

        const outgoingAudio = audioContext.createMediaStreamSource(outgoingStream)
        const incomingAudio = audioContext.createMediaStreamSource(incomingStream)

        const dest = audioContext.createMediaStreamDestination()

        outgoingAudio.connect(dest)
        incomingAudio.connect(dest)

        const mainStream = dest.stream

        this.mediaRecorder = new MediaRecorder(mainStream)
        this.getData()
      })
    },
    stopRecording () {
      this.mediaRecorder.stop()
      this.reset()
    },
    getData () {
      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        this.chunks.push(e.data)
      })
      this.mediaRecorder.start(this.timeSlice)
    },
    reset () {
      this.mediaRecorder = null
      this.paused = true
      this.audioUrl = null
      this.duration = 0
      this.currentTime = 0
      this.toggle = false
      this.chunks = []
    }
  }
}
