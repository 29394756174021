<template>
  <data-table-shared :headers="headers" :loading="loading" :items="computedInfo" height="" :perPage="100" :hide-footer="true" />
</template>

<script>
/* Components */
import DataTableShared from '@/app/shared/table/DataTableShared'
/* Helpers */
import timeHelper from '@/helpers/time.helper'
import { mapGetters } from 'vuex'
export default {
  name: 'CallDetailsInformationComponent',
  components: {
    DataTableShared
  },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Title', value: 'title' },
      { text: 'Details', value: 'value' }
    ]
  }),
  computed: {
    ...mapGetters('callDetails', [
      'callDetailsHeldGetter',
      'callDetailsAdrGetter',
      'callDetailsGetter'
    ]),
    computedInfo () {
      return [
        { title: 'Date/Time:', value: (this.callDetailsGetter.created_at) ? timeHelper.dateFormatter(this.callDetailsGetter.created_at) : '' },
        { title: 'Provider:', value: this.callDetailsAdrGetter?.providerInfo?.DataProviderString },
        { title: 'Callback Number:', value: this.callDetailsAdrGetter?.subscriberInfo?.SubscriberData?.vcard?.tel?.uri },
        { title: 'ESRK Number:', value: null },
        { title: 'Class of Service:', value: this.callDetailsAdrGetter?.serviceInfo?.ServiceType },
        { title: 'Name:', value: null },
        { title: 'Address:', value: this.getAddress() },
        { title: 'City, ST Zip:', value: this.getCityZip() },
        { title: 'Lat/Lon:', value: this.getLatLng() },
        { title: 'Cell/Sector ID:', value: null },
        { title: 'Comments:', value: this.callDetailsAdrGetter?.comment?.Comment }
      ]
    }
  },
  methods: {
    getAddress () {
      return `${this.callDetailsHeldGetter?.RD || ''} ${this.callDetailsHeldGetter?.HNO || ''} ${this.callDetailsHeldGetter?.country || ''}`
    },
    getCityZip () {
      return `${this.callDetailsHeldGetter?.A3 || ''} ${this.callDetailsHeldGetter?.A1 || ''} ${this.callDetailsHeldGetter?.PC || ''}`
    },
    getLatLng () {
      return `${this.callDetailsHeldGetter?.lat || ''} ${this.callDetailsHeldGetter?.lng || ''}`
    }
  }
}
</script>

<style scoped>

</style>
