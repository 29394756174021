var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12 py-0 d-flex","id":"map-container"}},[_c('div',{staticClass:"map-holder ",class:{
          'map-expaned': _vm.mapState === _vm.mapStates.expand
        },staticStyle:{"z-index":"5","width":"100%"},attrs:{"id":"map-holder"}},[_c('div',{staticClass:"map-search"},[_c('label',{attrs:{"for":"map-search-control"}},[_vm._v("Address:")]),_c('input',{staticClass:"map-search-control",attrs:{"type":"search","id":"map-search-control"}}),_c('div',{on:{"click":_vm.toggleMap}},[_c('v-icon',[_vm._v(_vm._s(_vm.mapState))])],1)]),_c('div',{staticClass:"map-content-holder",staticStyle:{"height":"calc(100% - 60px)"}},[_c('div',{staticClass:"map-style-switcher"},[_c('v-switch',{attrs:{"dense":"","flat":"","inset":"","color":_vm.isDarkMode ? _vm.colors.shades.white : _vm.colors.shades.black},on:{"change":_vm.changeMapStyle},model:{value:(_vm.isDarkMode),callback:function ($$v) {_vm.isDarkMode=$$v},expression:"isDarkMode"}})],1),_c('GmapMap',{ref:"mapRef",style:((_vm.mapState === 'mdi-fullscreen') ? "width: 100%; height: 100%" : 'width: 100%'),attrs:{"zoom":_vm.map.zoom,"center":{
              lat: _vm.map.lat,
              lng: _vm.map.lng
            },"map-type-id":"terrain","options":{
              panControl: false,
              zoomControl: false,
              scaleControl: false,
              rotateControl: false,
              mapTypeControl: false,
              disableDefaultUI: false,
              fullscreenControl: false,
              streetViewControl: false,
              styles: _vm.mapStyles[_vm.isDarkMode ? _vm.mapStyleTypes.dark : _vm.mapStyleTypes.light]
            }}},[_c('GmapAutocomplete'),_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,ref:"map-marker",refInFor:true,attrs:{"position":m.position,"clickable":true,"icon":_vm.markerOptions},on:{"click":function($event){return _vm.markerClicked(m, $event)}}},[_c('GmapInfoWindow',{ref:"map-info-window",refInFor:true},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(((index + 1) + ". " + (m.address)))+" ")])])],1)})],2)],1),_c('div')])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }