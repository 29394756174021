var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"parent-height",staticClass:"px-4 py-2",attrs:{"headers":_vm.headers,"items":_vm.items.value || [],"search":_vm.search,"options":_vm.options,"item-key":"id","selectable-key":"id","single-select":"","dense":"","dark":_vm.$vuetify.theme.dark,"height":_vm.fixedHeight - 150,"loading":_vm.loading,"loading-text":_vm.$t('loading'),"footer-props":{itemsPerPageOptions : [5,10,15]}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"clearable":"","label":_vm.$t('search')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getCallHistory.apply(null, arguments)},"click:clear":_vm.clearSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('v-btn',{attrs:{"slot":"append-outer","color":"primary","elevation":"0","disabled":_vm.loading},on:{"click":function($event){return _vm.getCallHistory()}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.call_from)+" ")]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormatter")(item.created_at))+" ")]}},{key:"item.call_direction",fn:function(ref){
var item = ref.item;
return [(item.call_direction === 'incoming')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-phone-incoming ")]):_vm._e(),(item.call_direction === 'outgoing')?_c('v-icon',{attrs:{"color":"accent"}},[_vm._v(" mdi-phone-outgoing ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('call-back-button',{attrs:{"dialNumber":item.call_from}}),_c('call-transfer-button',{attrs:{"dialNumber":item.call_from}}),_c('call-detail-button',{attrs:{"data":item}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }