<template>
  <v-btn
      class="pa-2"
      dark
      block
      height="100%"
      color="error"
      :loading="loading"
  >
    <v-icon color="white">mdi-map-marker-off</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'IncorrectLocationButton',
  data: () => ({
    loading: false
  })
}
</script>

<style scoped>

</style>
