<template>
  <v-row dense no-gutters>
    <v-col class="text-center mt-2 mb-1 px-1" cols="6" sm="6" md="4" lg="4" xl="4">
      <v-btn
        color="error"
        dark
        block
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-police-badge
        </v-icon>
        <span class="px-1">DPS</span>
      </v-btn>
    </v-col>
    <v-col class="text-center mt-2 mb-1 px-1" cols="6" sm="6" md="4" lg="4" xl="4">
      <v-btn
        color="success"
        dark
        block
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-hospital-box
        </v-icon>
        <span class="px-1">EMS</span>
      </v-btn>
    </v-col>
    <v-col class="text-center mt-2 mb-1 px-1" cols="6" sm="6" md="4" lg="4" xl="4">
      <v-btn
        color="warning"
        dark
        block
        min-height="70px"
        @click="referToLostNumber()"
      >
        <v-icon>
          mdi-fire
        </v-icon>
        <span class="px-1">Fire</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LostComponent',
  methods: {
    referToLostNumber () {
    }
  }
}
</script>

<style scoped>

</style>
