<template>
  <v-btn
      class="text-icon-button"
      :color="transferState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.hasActiveCall"
      height="100%"
  >
    <v-icon>{{ transferIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ transferLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TransferButton',
  data: () => ({
    disableTransfer: false,
    transferState: false,
    transferIcon: 'mdi-phone-forward',
    transferLabel: 'Transfer'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>
