<template>
  <v-card flat class="fill-height align-start pa-0 full-width" color="transparent">
    <v-tabs
      show-arrows
      height="40"
      dark
      background-color="transparent"
      color="#FFFFFF"
      v-model="tab"
    >
      <v-tab v-for="item in getItems" :key="item.tab" :cypress="item.cypress"
      >
        <template v-if="item && item.name === 'abandoned_call' && abandonedCounter> 0 ">
          <v-badge overlap color="red" :content="abandonedCounter">
            {{ item.tab }}
          </v-badge>
        </template>
        <template v-else>
          {{ item.tab }}
        </template>
      </v-tab>
    </v-tabs>
    <v-tabs-items style="height: calc(100% - 42px)" v-model="tab" class="full-width">
      <v-tab-item
        :eager="item.eager"
        v-for="item in getItems"
        :key="item.tab"
        class="fill-height"
      >
        <component v-if="!!item.component" :is="item.component" ></component>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import parentHeightMixin from '@/mixins/parent-height.mixin'
export default {
  name: 'tabsShared',
  props: ['items', 'abandonedCounter'],
  mixins: [parentHeightMixin],
  data: () => ({
    tab: null
  }),
  computed: {
    getItems () {
      const items = this.items.filter((item) => {
        return (item.visible !== undefined ? item.visible : true)
      }).sort((a, b) => a.order - b.order)

      return items
    }
  }
}
</script>

<style>
.v-window__container {
  height: 100% !important;
}
</style>
