import store from '@/store'
export default {
  methods: {
    setLocations (val) {
      if (val.lat && val.lng) {
        console.log('BEFORE PUSH', this.markers)
        this.markers.push(
          {
            position: {
              lat: parseFloat(this.callDetailsHeldGetter.lat),
              lng: parseFloat(this.callDetailsHeldGetter.lng)
            },
            address: `${store.getters['callDetails/callDetailsHeldGetter']?.RD || ''} ${store.getters['callDetails/callDetailsHeldGetter']?.HNO || ''} ${store.getters['callDetails/callDetailsHeldGetter']?.country}`
          }
        )
        console.log('AFTER PUSH', this.markers)
        this.map = {
          lat: parseFloat(this.callDetailsHeldGetter.lat),
          lng: parseFloat(this.callDetailsHeldGetter.lng),
          zoom: 18
        }
      } else {
        this.clearLocation()
      }
    },
    clearLocation () {
      this.markers = []
      this.map = {
        lat: 39.8097343,
        lng: -98.5556199,
        zoom: 4.5
      }
    }
  }
}
