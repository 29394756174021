import EmergencyCallDataParser from '@/modules/api/csp/parsers/emergency-call-data.parser'
import adrXmlParser from '@/modules/api/csp/parsers/adr/adr-xml.parser'

export default function adrParser (session) {
  return {

    emergencyCallDataParser: null,

    init () {
      this.emergencyCallDataParser = new EmergencyCallDataParser().parseAdr(session?._request?.headers?.['Call-Info'])
    },

    async parse () {
      this.init()
      return {
        comment: await this.comment(),
        subscriberInfo: await this.subscriberInfo(),
        serviceInfo: await this.serviceInfo(),
        deviceInfo: await this.deviceInfo(),
        providerInfo: await this.providerInfo()
      }
    },

    async comment () {
      return this.emergencyCallDataParser?.comment_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.comment_url, 'Comment') : null
    },

    async subscriberInfo () {
      return this.emergencyCallDataParser?.subscriber_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.subscriber_info_url, 'SubscriberInfo') : null
    },

    async serviceInfo () {
      return this.emergencyCallDataParser?.service_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.service_info_url, 'ServiceInfo') : null
    },

    async deviceInfo () {
      return this.emergencyCallDataParser?.device_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.device_info_url, 'DeviceInfo') : null
    },

    async providerInfo () {
      return this.emergencyCallDataParser?.provider_info_url ? await adrXmlParser().parse(this.emergencyCallDataParser?.provider_info_url, 'ProviderInfo') : null
    }

  }
}
