<template>
  <v-btn
    @click="mute()"
      class="text-icon-button"
      :color="callGetter.localMuted ? 'accent' : 'grey'"
      large
      dark
    width="100%"
      height="100%"
      :disabled="!callGetter.canMute"
  >
    <v-icon>{{ callGetter.localMuted ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon>
    <div class="font-weight-bold pt-2">{{  callGetter.localMuted ? $t('unmute') : $t('mute') }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import muteMixin from '@/mixins/sip/mute.mixin'

export default {
  name: 'MuteButton',
  mixins: [muteMixin],
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  }
}
</script>

<style scoped>

</style>
