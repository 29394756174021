<template>
  <div>
    <v-card tile>
      <v-row class='white--text' align='center' no-gutters>
        <v-col class='pa-8 phone-number-wrapper' cols='10'>
          <v-textarea
            v-model='phoneNumber'
            flat
            auto-grow
            :dark="$vuetify.theme.dark"
            @paste.prevent="onPhoneInputPaste"
            @keypress="onKeyPressValidatePhoneInput"
            @keyup="onPhoneInputUpdate"
            rows='1'
            :style='{ minHeight: minHeight + `px`, fontSize: fontSize + `px` }'
            class='phone-number-container'
          />
        </v-col>
        <v-col cols='2'>
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <v-btn id="button_Backspace" v-on='on' icon @click="backPhoneNumber" >
                <v-icon color='orange'>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Backspace</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator='{ on }'>
              <v-btn icon v-on='on' @click="clearPhoneNumber" >
                <v-icon color="red">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Clear</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <template v-for='(item, index) in items'>
          <v-col :cols='item.col' :key='index'>
            <v-btn
              :id="`button_${item.id}`"
              :class="'dial-pad-keys ' + item.css_class"
              :dark="$vuetify.theme.dark"
              :color='item.color'
              height='80px'
              tile
              block
              @click="action(item.value)"
              v-long-press="300"
              @long-press-start="longAction(item)"
            >
              <div class='d-flex flex-column'>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
                <v-icon v-if='item.longClickValue' color='grey' x-small>
                  {{ item.longClickIcon || item.longClickValue }}
                </v-icon>
              </div>
            </v-btn>
          </v-col>
        </template>
        <v-col cols='6'>
          <DialPadTransferButton></DialPadTransferButton>
        </v-col>
        <v-col cols='6'>
          <dial-pad-call-button></dial-pad-call-button>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

import LongPress from 'vue-directive-long-press'
import { mapActions, mapGetters } from 'vuex'
/* Mixins */
import keyBoardSync from '@/mixins/key-board-sync.mixin'
import callMixin from '@/mixins/sip/call.mixin'
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'
import DialPadTransferButton from '@/app/shared/buttons/DialPadTransferButton'
import DialPadCallButton from '@/app/shared/buttons/DialPadCallButton'
export default {
  name: 'DialPadComponent',

  mixins: [keyBoardSync, callMixin, warmTransferMixin],

  components: { DialPadTransferButton, DialPadCallButton },
  directives: {
    'long-press': LongPress
  },

  data: () => {
    return {
      longClickInProgress: false,
      phoneFieldFocused: false,
      tempPhoneNumber: '',
      phoneNumber: '',
      fontSize: '34',
      minHeight: '70'
    }
  },
  computed: {
    items () {
      return [
        { value: '1', id: 'Numpad1', icon: 'mdi-numeric-1', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '2', id: 'Numpad2', icon: 'mdi-numeric-2', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '3', id: 'Numpad3', icon: 'mdi-numeric-3', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '4', id: 'Numpad4', icon: 'mdi-numeric-4', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '5', id: 'Numpad5', icon: 'mdi-numeric-5', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '6', id: 'Numpad6', icon: 'mdi-numeric-6', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '7', id: 'Numpad7', icon: 'mdi-numeric-7', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '8', id: 'Numpad8', icon: 'mdi-numeric-8', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '9', id: 'Numpad9', icon: 'mdi-numeric-9', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '*', id: 'NumpadMultiply', icon: 'mdi-asterisk', color: 'primary_card', col: 4, css_class: 'dial_btn_number' },
        { value: '0', id: 'Numpad0', icon: 'mdi-numeric-0', color: 'primary_card', col: 4, longClickValue: '+', longClickIcon: 'mdi-plus', css_class: 'dial_btn_number' },
        { value: '#', id: 'NumpadDivide', icon: 'mdi-pound', color: 'primary_card', col: 4, css_class: 'dial_btn_number' }
      ]
    },

    ...mapGetters('dialPad', [
      'callPhoneNumberGetter',
      'callOutgoingSettingsGetter',
      'dialPadStatusGetter'
    ]),

    ...mapGetters('global', [
      'sipGetter', 'callGetter'
    ])
  },

  watch: {
    phoneNumber (newVal, oldVal) {
      if (newVal.length <= 5) {
        this.fontSize = '34'
        this.minHeight = '70'
      } else if (newVal.length > 5 && newVal.length <= 10) {
        this.fontSize = '22'
      } else if (newVal.length > 10 && newVal.length <= 14) {
        this.fontSize = '18'
      } else if (newVal.length > 14 && newVal.length <= 18) {
        this.fontSize = '14'
      } else if (newVal.length === 47) {
        this.phoneNumber = oldVal
        this.callPhoneNumberAction(this.phoneNumber)
      }
    }
  },

  async mounted () {
    await this.$dtmfTones.tones()
  },

  methods: {

    ...mapActions('dialPad', [
      'callPhoneNumberAction'
    ]),
    ...mapActions('notifications', [
      'notificationSnackBarAction'
    ]),

    /**
     * Validate phone number on paste. If is valid update local phone variable and state
     */
    onPhoneInputPaste (e) {
      const value = e.clipboardData.getData('text')
      if (this.isValidCharacter(value)) {
        this.phoneNumber = value
        this.callPhoneNumberAction(this.phoneNumber)
      }
    },

    /**
     * Check if char is valid character
     * @param char
     * @returns {*}
     */
    isValidCharacter (char) {
      return char.match('^[0-9+#*]+$') != null
    },

    /**
     * Validate phone input on key press.
     * @param e
     */
    onKeyPressValidatePhoneInput (e) {
      if (!this.isValidCharacter(e.key)) {
        e.preventDefault()
      }
    },

    /**
     * On Phone Input Key Up. Validate values and update store
     * @param e
     */
    onPhoneInputUpdate (e) {
      if (this.isValidCharacter(e.key) || ['Backspace', 'Enter'].includes(e.key)) {
        this.callPhoneNumberAction(this.phoneNumber)
      } else {
        e.preventDefault()
      }
    },

    backPhoneNumber () {
      if (!this.dialPadStatusGetter) return

      this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length - 1)
      this.callPhoneNumberAction(this.phoneNumber)
    },

    clearPhoneNumber () {
      if (!this.dialPadStatusGetter) return

      this.phoneNumber = ''
      this.callPhoneNumberAction(this.phoneNumber)
    },

    action (val) {
      if (!this.dialPadStatusGetter) return

      if (this.longClickInProgress) {
        this.longClickInProgress = false
        return true
      }
      switch (val) {
        case 'Backspace':
          this.backPhoneNumber()
          break
        // case "c":
        //   this.clearPhoneNumber();
        //   break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '*':
        case '#':
        case '+':
        default:
          if (this.phoneNumber.length < 46) {
            this.phoneNumber += val
            this.callPhoneNumberAction(this.phoneNumber)
            this.sendDTMF(val)
          } else this.sendDTMF(val)
          break
        case '0':
          this.sendDTMF(val)
          this.zero(val)
          break
      }
    },

    zero (val) {
      this.phoneNumber += val
      this.callPhoneNumberAction(this.phoneNumber)
    },

    sendDTMF (val) {
      if (this.$sip.answeredCallSession) {
        this.$sip.answeredCallSession.sendDTMF(val, {
          transportType: 'RFC2833'
        })
      }
    },

    /**
     * Action Performed on long click.
     * @param item
     */
    longAction (item) {
      if (!this.dialPadStatusGetter) return

      this.action(item.longClickValue || item.value)
      this.longClickInProgress = true
    }
  }
}
</script>

<style lang='scss' scoped>
  @import '@/sass/components/dialpad';
</style>
