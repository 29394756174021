<template>
  <v-btn
    height="100%"
    class="v-btn-justify-start"
    block
    :color="color"
    dark
    :disabled="!active"
    @click="answerCall()"
  >
    <v-icon
      left
      dark
    >
      {{ icon }}
    </v-icon>
    <template class="template">
      <span>{{ answered ? timerText : `L${number}` }}</span>
    </template>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
/* Mixins */
import answerMixin from '@/mixins/sip/answer.mixin'
import timerMixin from '@/mixins/timer.mixin'
import adrMixin from '@/mixins/sip/adr.mixin'
import heldMixin from '@/mixins/sip/held.mixin'
import esrkMixin from '@/mixins/sip/esrk.mixin'
import nameMixin from '@/mixins/sip/name.mixin'

export default {
  name: 'CallLineButton',
  mixins: [answerMixin, timerMixin, adrMixin, heldMixin, esrkMixin, nameMixin],
  props: ['index', 'number'],
  data: () => ({
    active: false,
    answered: false,
    session: null,
    color: 'error',
    interval: null,
    icon: 'mdi-phone-hangup',
    adr: null,
    held: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ])
  },
  watch: {
    active (val) {
      if (val) {
        this.icon = 'mdi-phone-ring'
        this.interval = setInterval(() => {
          this.blinking()
        }, 500)
      } else {
        this.icon = 'mdi-phone-hangup'
        this.answered = false
        clearInterval(this.interval)
        this.color = 'error'
      }
    },
    answered (val) {
      if (val) {
        this.startTimer()
        this.getEsrkNumber(this.session)
        this.getName(this.session)
        this.populateAdr(this.adr)
        this.populateHeld(this.held)
      } else {
        this.stopTimer()
      }
    },
    async session (val) {
      if (val) {
        await this.getHeld(val).then(data => {
          this.held = data
        })
        await this.getAdr(val).then(data => {
          this.adr = data
        })
      }
    }
  },
  methods: {
    ...mapMutations('adr', [
      'commentMutator',
      'deviceInfoMutator',
      'providerInfoMutator',
      'serviceInfoMutator',
      'subscriberInfoMutator'
    ]),
    ...mapMutations('held', [
      'heldMutator'
    ]),
    blinking () {
      if (this.color === 'error') {
        this.color = 'grey'
      } else {
        this.color = 'error'
      }
    },
    answerCall () {
      if (!this.callGetter.hasActiveCall) {
        this.answered = true
        this.answer(this.session)
        clearInterval(this.interval)
        this.color = 'success'
        this.icon = 'mdi-phone-in-talk'
      } else return false
    },
    async populateAdr (adr) {
      if (adr) {
        await this.clearAdr()
        setTimeout(async () => {
          await this.commentMutator(this.adr.comment)
          await this.deviceInfoMutator(this.adr.deviceInfo)
          await this.providerInfoMutator(this.adr.providerInfo)
          await this.serviceInfoMutator(this.adr.serviceInfo)
          await this.subscriberInfoMutator(this.adr.subscriberInfo)
        }, 500)
      } else await this.getAdr(this.session, true)
    },
    async populateHeld (held) {
      if (held) {
        await this.clearHeld()
        setTimeout(async () => {
          await this.heldMutator(held)
        })
      } else await this.getHeld(this.session, true)
    }
  }
}
</script>

<style scoped>

</style>
