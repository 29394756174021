<template>
  <v-tooltip v-if="isVisible" :key="buttonKey" bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        color="warning"
        @click.once="TransferToNumber"
        small
        min-width="0"
        width="30"
        height="30"
        class="mx-1 my-1"
        v-on="on"
        :disabled="isDisabled"
        :key="buttonKey"
      >
        <v-icon small color="white">
          mdi-call-merge
        </v-icon>
      </v-btn>
    </template>
    <span>Call Transfer</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
/* Mixins */
import warmTransferMixin from '@/mixins/sip/warm-transfer.mixin'
import clickOnceRefreshMixin from '@/mixins/click-once-refresh.mixin'

export default {
  name: 'CallTransferButton',
  props: ['dialNumber'],
  mixins: [warmTransferMixin, clickOnceRefreshMixin],
  data: () => ({
    buttonKey: null
  }),
  computed: {
    ...mapGetters('global', [
      'callGetter'
    ]),
    isVisible () {
      return this.callGetter.hasActiveCall
    },
    isDisabled () {
      return !(this.callGetter.hasActiveCall && !this.callGetter.transferring)
    }
  },
  methods: {
    TransferToNumber () {
      this.warmTransfer(this.dialNumber)
      this.refreshButton()
    }
  },
  mounted () {
    this.buttonKey = this.$uuidKey()
  }
}
</script>

<style scoped>

</style>
