import store from '@/store'
import sipHelper from '@/helpers/sip.helper'
export default {
  methods: {
    callOrTransfer (target) {
      if (!store.getters['global/callGetter'].hasActiveCall) {
        this.$sip.call(sipHelper.getTarget(target, this.$sip.domain), sipHelper.getOptions())
      } else {
        this.$sip.answeredCallSession.refer(sipHelper.getTarget(target, this.$sip.domain), sipHelper.getOptions())
      }
    }
  }
}
