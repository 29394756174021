<template>
  <v-btn
      class="text-icon-button"
      :color="conferenceState ? 'grey' : 'primary'"
      large
      dark
      width="100%"
      :disabled="!sipGetter.isConference"
      height="100%"
  >
    <v-icon>{{ conferenceIcon }}</v-icon>
    <div class="font-weight-bold pt-2">{{ conferenceLabel }}</div>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConferenceButton',
  data: () => ({
    disableConference: false,
    conferenceState: false,
    conferenceIcon: 'mdi-account-group',
    conferenceLabel: 'Conference'
  }),
  computed: {
    ...mapGetters('global', [
      'sipGetter'
    ])
  }
}
</script>

<style scoped>

</style>
